var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"streaming-chart-list-item",class:`streaming-chart-list-item${_vm.isTitle ? '--title' : ''}`,attrs:{"data-position":_vm.chartPosition}},[_c('td',{staticClass:"streaming-chart-list-item__content"},[_c('p',{staticClass:"streaming-chart-list-item__position",class:{ 'streaming-chart-list-item__position--title': _vm.isTitle }},[_vm._v(" "+_vm._s(_setup.formattedChartPosition)+". ")])]),_c('td'),_c('td',{staticClass:"streaming-chart-list-item__content"},[_c(_setup.TitleDetailLink,{attrs:{"fullPath":_vm.titleUrl,"title":_vm.title}},[_c('div',{staticClass:"streaming-chart-list-item__link-content",on:{"click":function($event){return _vm.$emit('click')}}},[_c(_setup.PictureComp,{staticClass:"streaming-chart-list-item__poster",attrs:{"alt":_vm.titleName,"imageUrl":_setup.imgSrc,"image-profile":"poster_global"}}),_c('div',{staticClass:"streaming-chart-list-item__title",class:{
						'streaming-chart-list-item__title--title': _vm.isTitle,
					},attrs:{"title":_vm.titleName}},[_c('span',{staticClass:"streaming-chart-list-item__title-name"},[_vm._v(_vm._s(_vm.titleName)+" ")]),(_vm.showSeason)?_c('span',{staticClass:"streaming-chart-list-item__season",class:{ 'streaming-chart-list-item__season--title': _vm.isTitle }},[_vm._v("("+_vm._s(_setup.seasonNumber)+")")]):_vm._e()])],1)])],1),_c('td',[(_setup.showArrow)?_c('div',{staticClass:"arrow-container",class:{
				'arrow-container--up': _vm.chartPositionDynamic === 'UP',
				'arrow-container--stable': _vm.chartPositionDynamic === 'STABLE',
				'arrow-container--down': _vm.chartPositionDynamic === 'DOWN',
			}},[_c('FontAwesomeIcon',{class:_setup.arrowClasses,attrs:{"icon":"caret-up","size":"lg"}}),_c('p',[_vm._v(_vm._s(_setup.trendDifference))])],1):(_setup.isNew)?_c('div',{staticClass:"new"},[_vm._v(" "+_vm._s(_vm.$t('WEBAPP_MENU_NEW'))+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }