import {
	ModuleContentTitleTrend,
	MovieOrShowOrSeason,
	ObjectType,
	Season,
	StreamingChartsTitlesEdge,
} from '@/@types/graphql-types';

import { useI18n } from '@/helpers/composables/useI18n';
import { useLanguageStore } from '@/helpers/composables/useStores';
import { TranslateResult } from 'vue-i18n';

/**
 * This function returns a list of titles to be displayed in a streaming chart.
 * @param titleId - The ID of the title which the streaming chart is centred around.
 * @param titleEdges - The array of title edges that can be displayed in the streaming chart.
 * @param range - The number of titles to be displayed before and after the central title in the streaming chart.
 * @returns A list of streaming chart titles with the central title placed in the middle position of the array.
 */
export function sliceTitles(titleId: string | undefined, titleEdges: StreamingChartsTitlesEdge[], range: number) {
	const titles = titleEdges || [];
	const title = titles.find(title => title?.node?.id === titleId);
	const rank = title?.streamingChartInfo?.rank;

	// Set the start and end indices for default slicing.
	const sliceStart = range * 2 + 1 - range;
	const sliceEnd = range * 2 + 1 + range + 1;

	// If the title doesn't have a rank (e.g. is null/undefined), slice based on the fixed default range as a fallback.
	if (!rank) {
		return titles.slice(sliceStart, sliceEnd);
	}

	// Calculate the dynamic start and end indices for slicing. This is necessary when viewing titles in the top positions of the chart.
	// Dynamically calculate the beginning of the array to be sliced. If this number ends up being less than 0 (i.e. the title's rank is less than the specified range + 1), use 0.
	const dynamicSliceStart = Math.max(rank - (range + 1), 0);
	// Dynamically calculate the end of the array to be sliced. As we hit the top positions where dynamicSliceStart is 0, add the specified range (+1) on top to buff out the end of the list.
	const dynamicSliceEnd = rank + range + (dynamicSliceStart === 0 ? range + 1 - rank : 0);

	// If the rank is less than or equal to the total chart range, slice dynamically.
	if (rank <= range * 2 + 1) {
		return titles.slice(dynamicSliceStart, dynamicSliceEnd);
	}

	// Otherwise, slice based on the default fixed range.
	return titles.slice(sliceStart, sliceEnd);
}

export function provideStreamingChartSpinningText(
	titleId: string,
	title: StreamingChartsTitlesEdge | undefined,
	titleNodes: MovieOrShowOrSeason[]
) {
	const { country } = useLanguageStore();
	const { t, tc } = useI18n();

	// Setting all the necessary variables for the spinning text.
	const titleNode = title?.node as MovieOrShowOrSeason as Season;
	const titleName = titleNode?.show?.content?.title ?? titleNode?.content?.title;
	const streamingChartInfo = title?.streamingChartInfo;
	const objectType = titleNode?.objectType;

	// Getting the country name
	let countryTranslatable = t('WEBAPP_COUNTRY_' + country.value);
	if (country.value === 'US') countryTranslatable = t('WEBAPP_COUNTRY_THE_US');
	if (country.value === 'GB') countryTranslatable = t('WEBAPP_COUNTRY_THE_GB');

	const currentRank = t('WEBAPP_STREAMING_CHART_SPINNINGTEXT_CURRENTRANK', {
		title: titleName,
		rank: streamingChartInfo?.rank,
	});

	// Setting the translations for the different types of rank movements a title can make
	let rankMovement = '' as TranslateResult;
	if (streamingChartInfo) {
		if (streamingChartInfo.trend === ModuleContentTitleTrend.Up) {
			rankMovement =
				streamingChartInfo.trendDifference === 1
					? t(
							objectType === 'MOVIE'
								? 'WEBAPP_STREAMING_CHART_SPINNINGTEXT_MOVIE_RANKINCREASE1'
								: 'WEBAPP_STREAMING_CHART_SPINNINGTEXT_SHOW_RANKINCREASE1',
							{ rank_change: streamingChartInfo.trendDifference }
					  )
					: t(
							objectType === 'MOVIE'
								? 'WEBAPP_STREAMING_CHART_SPINNINGTEXT_MOVIE_RANKINCREASEMULTIPLE'
								: 'WEBAPP_STREAMING_CHART_SPINNINGTEXT_SHOW_RANKINCREASEMULTIPLE',
							{ rank_change: streamingChartInfo.trendDifference }
					  );
		} else if (streamingChartInfo.trend === ModuleContentTitleTrend.Stable) {
			rankMovement = tc(
				`WEBAPP_STREAMING_CHART_SPINNINGTEXT_${objectType === ObjectType.Movie ? 'MOVIE' : 'SHOW'}_RANKSAME`
			);
		} else if (streamingChartInfo.trend === ModuleContentTitleTrend.Down) {
			rankMovement =
				streamingChartInfo.trendDifference === -1
					? t(
							`WEBAPP_STREAMING_CHART_SPINNINGTEXT_${
								objectType === ObjectType.Movie ? 'MOVIE' : 'SHOW'
							}_RANKDECREASE1`,
							{
								rank_change: streamingChartInfo.trendDifference,
							}
					  )
					: t(
							`WEBAPP_STREAMING_CHART_SPINNINGTEXT_${
								objectType === ObjectType.Movie ? 'MOVIE' : 'SHOW'
							}_RANKDECREASEMULTIPLE`,
							{
								rank_change: streamingChartInfo.trendDifference,
							}
					  );
		}
	}

	// If the title is in the first position, return the rank movement if it exists, otherwise just return the current rank.
	if (streamingChartInfo?.rank === 1) {
		return rankMovement ? `${currentRank} ${rankMovement}` : currentRank;
	}

	// If the title is not in the first position, compare it to the titles above and below it.
	let comparison = '' as TranslateResult;

	const getAdjacentTitle = (indexShift: number) => {
		const thisTitleIndex = titleNodes?.findIndex((title: MovieOrShowOrSeason) => title.id === titleId);
		return titleNodes?.[thisTitleIndex + indexShift];
	};

	if (objectType === ObjectType.Movie) {
		comparison = t('WEBAPP_STREAMING_CHART_SPINNINGTEXT_MOVIE_COMPARISON', {
			country: countryTranslatable,
			position_below: (getAdjacentTitle(1) as MovieOrShowOrSeason)?.content?.title,
			position_above: (getAdjacentTitle(-1) as MovieOrShowOrSeason)?.content?.title,
		});
	} else if (objectType === ObjectType.Show || objectType === ObjectType.ShowSeason) {
		comparison = t('WEBAPP_STREAMING_CHART_SPINNINGTEXT_SHOW_COMPARISON', {
			country: countryTranslatable,
			position_below: (getAdjacentTitle(1) as Season)?.show
				? (getAdjacentTitle(1) as Season)?.show?.content?.title
				: (getAdjacentTitle(1) as MovieOrShowOrSeason)?.content?.title,
			position_above: (getAdjacentTitle(-1) as Season)?.show
				? (getAdjacentTitle(-1) as Season)?.show?.content?.title
				: (getAdjacentTitle(-1) as MovieOrShowOrSeason)?.content?.title,
		});
	}

	return `${currentRank} ${rankMovement ?? ''} ${comparison ?? ''}`;
}
